export class DownloadAgencyCorrectNameBase {
  public id: number;
  public pnr: string;
  public firstName: string;
  public newFirstName: string;
  public lastName: string;
  public newLastName: string;
  public modificationDate: Date;
  public criterionCode: string;
  public statusMessage: string;
  public statusCode: string;
  public email: string;
  public flightNumbers: string;
  public marketingAirlineCode: string;
  public segmentDeparture: string;
  public segmentArrival: string;
  public userName: string;
  public marketName: string;
  public agencyGroupName: string;
  public typeReservation:String;

  public constructor(
    id: number,
    pnr: string,
    firstName: string,
    newFirstName: string,
    lastName: string,
    newLastName: string,
    modificationDate: Date,
    criterionCode: string,
    statusMessage: string,
    statusCode: string,
    email: string,
    flightNumbers: string,
    marketingAirlineCode: string,
    segmentDeparture: string,
    segmentArrival: string,
    userName: string,
    marketName: string,
    agencyGroupName: string,
    typeReservation:String
  ) {
    this.id = id;
    this.pnr = pnr;
    this.firstName = firstName;
    this.newFirstName = newFirstName;
    this.lastName = lastName;
    this.newLastName = newLastName;
    this.modificationDate = modificationDate;
    this.criterionCode = criterionCode;
    this.statusMessage = statusMessage;
    this.statusCode = statusCode;
    this.email = email;
    this.flightNumbers = flightNumbers;
    this.marketingAirlineCode = marketingAirlineCode;
    this.segmentDeparture = segmentDeparture;
    this.segmentArrival = segmentArrival;
    this.userName = userName;
    this.marketName = marketName;
    this.agencyGroupName = agencyGroupName;
    this.typeReservation = typeReservation;
  }
}