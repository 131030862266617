import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WaiverPostsaleService } from '../../../services/waiver-postsale.service';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { DownloadAgencyCorrectNameReport } from '../../../models/download-agency-report-correctname.model';
import { CorrectNameService } from '../../../services/correct-name.service';
import { DatePipe } from '@angular/common';
import { MessageErrorService } from '../../../services/message-error.service';
import { MessageService } from '../../../services/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  loading: boolean = false;
  currentDate = new Date();
  startDate: Moment;
  endDate: Moment;
  report: DownloadAgencyCorrectNameReport[] = [];
  private datepipe: DatePipe = new DatePipe('en-US');
  
  private criterionMap: { [key: number]: string } = {};

  constructor(
    private waiverPostsaleService: WaiverPostsaleService,
    private router: Router,
    private adapter: DateAdapter<Moment>,
    private correctNameService: CorrectNameService,
    private messageErrorService: MessageErrorService,
    private messageService: MessageService,
    private translateService: TranslateService 
  ) {}

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.updateCriterionMap();
    });

    this.updateCriterionMap();
  }

  private updateCriterionMap(): void {
    this.criterionMap = {
      1: this.translateService.instant('correctName.motives.tresLetras') || 'Desconocido',
      3: this.translateService.instant('correctName.motives.nombresApellidosInvertidos') || 'Desconocido',
      4: this.translateService.instant('correctName.motives.agregarNombreApellido') || 'Desconocido',
      6: this.translateService.instant('correctName.motives.duplicidadNombre') || 'Desconocido',
      7: this.translateService.instant('correctName.motives.correccionAdicionExclusion') || 'Desconocido'
    };
  }


  navigateToMain() {
    this.router.navigate(['/agency/correct-name']);
  }

  startDateChanged(startDate: MatDatepickerInputEvent<Moment>) {
    if (this.endDate && startDate.value > this.endDate) {
      this.endDate = null;
    }
    this.startDate = startDate.value;
  }

  endDateChanged(endDate: MatDatepickerInputEvent<Moment>) {
    this.endDate = endDate.value;
  }

  setCalendarLanguage() {
    this.adapter.setLocale('es');
  }

  generateReport() {
    this.loading = true;
  
    if (!this.startDate || !this.endDate) {
      this.loading = false;
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'), 
        this.translateService.instant('info.dateRangeInvalid')
      );
      return;
    }
  
    if (this.startDate.isAfter(this.endDate)) {
      this.loading = false;
      this.messageService.showWarningMessage(
        this.translateService.instant('button.confirm'), 
        this.translateService.instant('info.startDateBeforeEndDate')
      );
      return;
    }
  
    const formattedStartDate = this.datepipe.transform(this.startDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
    const formattedEndDate = this.datepipe.transform(this.endDate.toDate(), 'yyyy-MM-ddTHH:mm:ss');
  
    this.correctNameService.getNameCorrectionsBetweenDates(formattedStartDate, formattedEndDate)
      .subscribe({
        next: (data: DownloadAgencyCorrectNameReport[]) => {
          this.report = data;
          this.downloadCsv(this.report);
        },
        error: (error: HttpErrorResponse) => {
          this.messageErrorService.sendError(error, 'error.generateReport'); 
          console.error('Error al generar el reporte: ', error);
          this.loading = false;
        },
        complete: () => this.loading = false
      });
  }

  private downloadCsv(data: DownloadAgencyCorrectNameReport[]) {
    const csvContent = this.convertToCsv(data);
  
    const BOM = '\uFEFF';  
    const csvWithBOM = BOM + csvContent; 
    
 
    const blob = new Blob([csvWithBOM], { type: 'text/csv;charset=utf-8;' });
    

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', 'reporte_correcciones.csv');
    
    document.body.appendChild(link);
    link.click();
    
    document.body.removeChild(link);
  }
  
  

 private convertToCsv(data: DownloadAgencyCorrectNameReport[]): string {
    const csvRows = [];
    
    const headers = [
        'id', 
        'pnr', 
        'firstName', 
        'newFirstName', 
        'lastName', 
        'newLastName', 
        'modificationDate', 
        'criterionCode', 
        'statusCode', 
        'statusMessage', 
        'email', 
        'flightNumbers', 
        'marketingAirlineCode', 
        'segmentDeparture', 
        'segmentArrival', 
        'userName', 
        'marketName', 
        'agencyGroupName',
        'typeReservation'
    ];

    csvRows.push(headers.join(';'));

    for (const row of data) {
        const convertedRow = {
            id: row.id,
            pnr: row.pnr,
            firstName: row.firstName,
            newFirstName: row.newFirstName,
            lastName: row.lastName,
            newLastName: row.newLastName,
            modificationDate: this.datepipe.transform(row.modificationDate, 'yyyy-MM-ddTHH:mm:ss'),
            criterionCode: this.criterionMap[row.criterionCode] || 'Desconocido',
            statusCode: row.statusCode,
            statusMessage: row.statusMessage,
            email: row.email,
            flightNumbers: row.flightNumbers,
            marketingAirlineCode: row.marketingAirlineCode,
            segmentDeparture: row.segmentDeparture,
            segmentArrival: row.segmentArrival,
            userName: row.userName,
            marketName: row.marketName,
            agencyGroupName: row.agencyGroupName,
            typeReservation: row.typeReservation
        };
        csvRows.push(headers.map(header => JSON.stringify(convertedRow[header])).join(';'));
    }

    return csvRows.join('\n');
}


}
