import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { BusinessException, Exception, SystemException } from '../models/Exception.model';
import { TranslateService } from '@ngx-translate/core';
import swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class MessageErrorService {

  constructor(private messageService: MessageService, private translateService: TranslateService) { }

  sendError(error: HttpErrorResponse, message: string) {
    if (error?.error?.type != undefined && error?.error?.type != null) {
      this.messageService.showErrorMessage(
        new Exception(
          error.error.type,
          error.error.type !== BusinessException && error.error.type != 'AlterationValueException' ? this.translateService.instant(message) : error.error.description,
          error.status
        ),
        this.translateService.instant('button.confirm')
      );
    } else {
      this.messageService.showErrorMessage(
        new Exception(SystemException, this.translateService.instant(message), error.status),
        this.translateService.instant('button.confirm'));
    }
  }

  sendErrorSecondVersion(error: HttpErrorResponse): boolean{
    let refresh = false;
    if (error.status == 406) {
      this.messageService.showSuccessMessage("OK", this.translateService.instant('success.loadSuccess'), "")

    } else {
      swal.fire({
        html: '<p>' + this.translateService.instant('login.error.loadError') + '</p>',
        confirmButtonText: this.translateService.instant('button.confirm'),
        confirmButtonColor: '#d33',
        icon: 'error',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          //Success
          refresh = true;
        }
      });
    }
    return refresh;
  }

  prepareDeleteMessage(message: string): SweetAlertOptions<unknown, any>{
    return {
      html: '<p>' + this.translateService.instant(message) + '</p>',
      icon: 'question',
      confirmButtonText: this.translateService.instant('button.yesSure'),
      confirmButtonColor: '#d33',
      showCancelButton: true,
      cancelButtonText: this.translateService.instant('button.cancel'),
      allowOutsideClick: false,
      reverseButtons: true
    };
  }
}
